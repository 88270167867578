import React, { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Type, Scope } from '@socrata/core-federations-api';

import I18n from 'common/i18n';
import ChooserRadioButton from 'common/components/ChooserRadioButton';

import { getType, getScope } from './redux/selectors';
import { setFederationType } from './redux/actions';

const FederationType: FunctionComponent = () => {
  const dispatch = useDispatch();
  const selectedScope = useSelector(getScope);
  const selectedType = useSelector(getType);

  const onSetFederationType = (type: Type, scope: Scope) => dispatch(setFederationType(type, scope));
  const renderChooserForType = (type: Type, scope: Scope) => (
    <ChooserRadioButton
      borders={true}
      hoverMessage={`shared.federations.add_federation.federation_type.${scope}.${type}.title`}
      name={type}
      onSelection={() => onSetFederationType(type, scope)}
      selected={selectedType === type && selectedScope === scope}
    >
      <h5 className="h5">
        {I18n.t(`shared.federations.add_federation.federation_type.${scope}.${type}.title`)}
      </h5>
      <div className="small">
        {I18n.t(`shared.federations.add_federation.federation_type.${scope}.${type}.description`)}
      </div>
    </ChooserRadioButton>
  );

  return (
    <div className="federation-type-chooser">
      {renderChooserForType(Type.Catalog, Scope.Audience)}
      {renderChooserForType(Type.Data, Scope.Audience)}
    </div>
  );
};

export default FederationType;
