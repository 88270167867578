import React, { FunctionComponent } from 'react';
import uniq from 'lodash/uniq';

import { Federation } from '@socrata/core-federations-api';

import I18n from 'common/i18n';
import Autocomplete from 'common/components/Autocomplete';

import { searchDomainCNames, toResults } from '../helpers';

interface FederationsSearchBarProps {
  onSearch: (query?: string) => void;
  federations: Federation[];
}

interface Match {
  start: number;
  length: number;
}

interface Result {
  title: string;
  matches: Match[];
}

interface Results {
  results: Result[];
}

export const federationsAutocomplete = (federations: Federation[], query: string): Promise<Results> => {
  const q = query.toLowerCase();
  const domainCNames = searchDomainCNames(federations, q);
  const uniqueDomainCNames = uniq(domainCNames);
  const results = toResults(uniqueDomainCNames as string[], q);
  return Promise.resolve({ results });
};

const FederationsSearchBar: FunctionComponent<FederationsSearchBarProps> = ({ onSearch, federations }) => {
  const autocompleteOptions = {
    animate: true,
    anonymous: false,
    collapsible: false,
    getSearchResults: (query: string) => federationsAutocomplete(federations, query),
    millisecondsBeforeSearch: 100,
    onChooseResult: (query: string) => onSearch(query),
    onClearSearch: () => onSearch(undefined),
    placeholder: I18n.t('shared.federations.placeholders.search_domains')
  };

  return (
    <div className="federations-search-bar search-bar">
      <Autocomplete className="autocomplete" {...autocompleteOptions} />
    </div>
  );
};

export default FederationsSearchBar;
