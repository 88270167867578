import flatMap from 'lodash/flatMap';

import { Federation, ErrorResponseCodeEnum } from '@socrata/core-federations-api';

import { TableTab } from './types';

/** Default search boost to use for catalog federations */
export const DefaultSearchBoost = '0.8';

/**
 * Returns all target & source domainCNames that includes
 * the query (includes duplicates)
 * @param federations: A list of federation objects
 * @param query An all lower-case query
 * @returns A list of domainCNames
 */
export function searchDomainCNames(federations: Federation[], query: string) {
  return flatMap(federations, (federation: Federation) => [
    federation.targetDomainCName!,
    federation.sourceDomainCName!
  ]).filter((domainCName: string) => domainCName?.toLowerCase().includes(query));
}

/**
 * Returns all federation objects where the source or target
 * domainCNames includes the query (if any), and the federation
 * direction matches the specified table tab (ALL/IN/OUT).
 * @param federations A list of federation objects
 * @param query An all lower-case query
 * @param tableTab Tab (ALL/IN/OUT) the user is on.
 * @returns A list of federation objects
 */
export function searchFederations(federations: Federation[], query: string | undefined, tableTab: TableTab) {
  return federations
    .filter((federation) => {
      if (tableTab == TableTab.ALL) {
        return true;
      } else if (federation.isIncomingFederation) {
        return tableTab === TableTab.IN;
      } else {
        return tableTab === TableTab.OUT;
      }
    })
    .filter((federation) => {
      const targetDomainCName = federation.targetDomainCName?.toLowerCase();
      const sourceDomainCName = federation.sourceDomainCName?.toLowerCase();
      return !query || targetDomainCName?.includes(query) || sourceDomainCName?.includes(query);
    });
}

/**
 * Turns a list of domainCNames into a list of Result objects
 * using the query to find indices of where to eventually highlight
 * a Result:
 * {
 *   title: domainCName
 *   matches: [
 *     {
 *       start: start index of the query in domainCName,
 *       length: the length of the query
 *     }
 *   ]
 * }
 * @param domainCNames A list of domainCNames
 * @param query An all lower-case query
 * @returns A list of Result objects
 */
export function toResults(domainCNames: string[], query: string) {
  return domainCNames.map((domainCName: string) => {
    const startIndex = domainCName.toLowerCase().indexOf(query);
    const matches = [{ start: startIndex, length: query.length }];
    return { title: domainCName, matches };
  });
}
