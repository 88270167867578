import React from 'react';

import { Federation } from '@socrata/core-federations-api';

import FederationsCount from './FederationsCount';
import FederationTableTabs from './FederationTableTabs';
import { TableTab } from '../types';
import AddFederation from './AddFederation';

interface Props {
  /**
   * Current active tab. It's highlighted in the UI.
   */
  activeTab: TableTab;
  /**
   * List of federation table rows.
   * Used to display type counts.
   */
  federations: Federation[];
  /**
   * Called when the user clicks on a tab.
   */
  onTabChanged: (tab: TableTab) => void;
}

const FederationsHeader = ({ federations, onTabChanged, activeTab }: Props) => (
  <div className="federations-header admin-dashboard">
    <FederationTableTabs activeTab={activeTab} onTabChanged={onTabChanged} />
    <FederationsCount federations={federations} />
    <AddFederation />
  </div>
);

export default FederationsHeader;
