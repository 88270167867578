import React, { FunctionComponent } from 'react';

import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';

const scope = 'shared.federations.actions';

export interface ActionCellModalProps {
  /** Whether or not we're currently waiting for an async action to finish */
  busy: boolean;

  /** The current modal screen being shown */
  modalScreen: ModalScreen;

  /** Function to call to set the current modal screen */
  setModalScreen: React.Dispatch<React.SetStateAction<ModalScreen>>;

  /** Function to call to delete the federation for the current row in the table */
  onDeleteFederation: () => Promise<void>;
}

/** Represents all the different types of modal that we show */
export enum ModalScreen {
  /** Modal is not being shown */
  NONE,

  /** Non-superadmins get this when they try to terminate a data federation. */
  CANNOT_TERMINATE_DATA_FEDERATION,

  /** Do you really want to delete this? */
  CONFIRM_TERMINATE_CATALOG_FEDERATION,

  /** Do you really want to delete this? Only superadmins get this. */
  CONFIRM_TERMINATE_DATA_FEDERATION,

  /** Do you really want to cancel your federation request? */
  CONFIRM_CANCEL_REQUEST
}

/** Config used to render a modal; each ModalScreen has a specific config */
interface ModalConfig {
  /** Title to show at the top of the modal */
  title: string;

  /** Content to render in the body of the modal */
  content: JSX.Element;

  /** (Optional) footer to render on the footer */
  footer?: JSX.Element;
}

/** Get modal config for CONFIRM_CANCEL_REQUEST ModalScreen */
const getConfirmCancelRequestModalConfig = (
  dismissModal: () => void,
  busy: boolean,
  onDeleteFederation: () => Promise<void>
): ModalConfig => ({
  title: I18n.t('cancel_request', { scope }),
  content: (
    <>
      <p>{I18n.t('cancel_warning_message', { scope })}</p>
      <p>{I18n.t('continue_question', { scope })}</p>
    </>
  ),
  footer: (
    <>
      <Button variant={VARIANTS.DEFAULT} onClick={dismissModal} disabled={busy}>
        {I18n.t('keep_request', { scope })}
      </Button>
      <Button variant={VARIANTS.PRIMARY} onClick={onDeleteFederation} disabled={busy}>
        {I18n.t('cancel_request', { scope })}
      </Button>
    </>
  )
});

/** Get modal config for CANNOT_TERMINATE_DATA_FEDERATION ModalScreen */
const getCannotTerminateDataFederationModalConfig = (
  // these params aren't used here but are needed to satisfy types
  dismissModal: () => void,
  busy: boolean,
  onDeleteFederation: () => Promise<void>
): ModalConfig => ({
  title: I18n.t('terminate_data_federation', { scope }),
  content: (
    <>
      <p>{I18n.t('cannot_terminate_data_federation_p1', { scope })}</p>
      <p>{I18n.t('cannot_terminate_data_federation_p2', { scope })}</p>
    </>
  )
});

/** Get modal config for CONFIRM_TERMINATE_CATALOG_FEDERATION ModalScreen */
const getConfirmTerminateCatalogFederationModalConfig = (
  dismissModal: () => void,
  busy: boolean,
  onDeleteFederation: () => Promise<void>
): ModalConfig => ({
  title: I18n.t('terminate_catalog_federation', { scope }),
  content: (
    <>
      <p>{I18n.t('terminate_catalog_federation_warning_message', { scope })}</p>
      <p>{I18n.t('continue_question', { scope })}</p>
    </>
  ),
  footer: (
    <>
      <Button variant={VARIANTS.DEFAULT} onClick={dismissModal} disabled={busy}>
        {I18n.t('keep_federation', { scope })}
      </Button>
      <Button variant={VARIANTS.ERROR} onClick={onDeleteFederation} disabled={busy}>
        {I18n.t('terminate_catalog_federation', { scope })}
      </Button>
    </>
  )
});

/** Get modal config for CONFIRM_TERMINATE_DATA_FEDERATION ModalScreen */
const getConfirmTerminateDataFederationModalConfig = (
  dismissModal: () => void,
  busy: boolean,
  onDeleteFederation: () => Promise<void>
): ModalConfig => ({
  title: I18n.t('terminate_data_federation', { scope }),
  content: (
    <>
      <p>{I18n.t('terminate_data_federation_warning_message', { scope })}</p>
      <p>{I18n.t('continue_question', { scope })}</p>
    </>
  ),
  footer: (
    <>
      <Button variant={VARIANTS.DEFAULT} onClick={dismissModal} disabled={busy}>
        {I18n.t('keep_federation', { scope })}
      </Button>
      <Button variant={VARIANTS.ERROR} onClick={onDeleteFederation} disabled={busy}>
        {I18n.t('terminate_data_federation', { scope })}
      </Button>
    </>
  )
});

/**
 * This object contains a mapping of ModalScreen -> function to get config for that screen
 */
const modalConfigs: {
  [key in ModalScreen]: (
    dismissModal: () => void,
    busy: boolean,
    onDeleteFederation: () => Promise<void>
  ) => ModalConfig;
} = {
  [ModalScreen.NONE]: undefined as never, // should never get here
  [ModalScreen.CONFIRM_CANCEL_REQUEST]: getConfirmCancelRequestModalConfig,
  [ModalScreen.CANNOT_TERMINATE_DATA_FEDERATION]: getCannotTerminateDataFederationModalConfig,
  [ModalScreen.CONFIRM_TERMINATE_CATALOG_FEDERATION]: getConfirmTerminateCatalogFederationModalConfig,
  [ModalScreen.CONFIRM_TERMINATE_DATA_FEDERATION]: getConfirmTerminateDataFederationModalConfig
};

/**
 * Some actions require an intermediate modal, like confirming the termination
 * of a federation. This renders those, based on the `modalScreen` prop.
 */
const ActionCellModal: FunctionComponent<ActionCellModalProps> = ({
  busy,
  modalScreen,
  setModalScreen,
  onDeleteFederation
}) => {
  if (modalScreen === ModalScreen.NONE) {
    return null;
  }

  const dismissModal = () => {
    // Can't dismiss modal if we're in the middle of something.
    // There is no canceling the request!
    if (!busy) {
      setModalScreen(ModalScreen.NONE);
    }
  };

  // grab the modal config for our current modal screen
  const { title, content, footer } = modalConfigs[modalScreen](dismissModal, busy, onDeleteFederation);

  return (
    <Modal onDismiss={dismissModal}>
      <ModalHeader title={title} onDismiss={dismissModal} />
      <ModalContent>{content}</ModalContent>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
};

export default ActionCellModal;
