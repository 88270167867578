import React, { FunctionComponent } from 'react';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { Type, Scope } from '@socrata/core-federations-api';

import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';
import Modal, { ModalHeader, ModalContent, ModalFooter } from 'common/components/Modal';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import { getCurrentModalScreen, getType, getScope, getTargetDomainCname, getIsBusy } from './redux/selectors';
import { setModalScreen } from './redux/actions';

import FederationType from './FederationType';
import DataFederationInformation from './DataFederationInformation';
import CnameSearch from './CnameSearch';
import FederationOptions from './FederationOptions';
import SubmitFederationButton from './SubmitFederationButton';

export enum AddFederationModalScreen {
  /**
   * User is selecting the "type" of federation to add.
   * This is the first step in the add federation workflow.
   *
   * This is only shown if the current domain is eligible to be a "data federation" source domain,
   * otherwise we just assume that this is only going to be a catalog federation.
   */
  SELECT_FEDERATION_TYPE,

  /**
   * Screen that shows info/warnings/caveats about data federation.
   * This is only shown if the user selects data federation as the federation type.
   */
  DATA_FEDERATION_INFORMATION,

  /** Screen to search for the CNAME of a target domain  */
  CNAME_SEARCH,

  /**
   * Verification of federation and additional options.
   * This changes depending on the type of federation that was selected.
   */
  FEDERATION_OPTIONS
}

/** Config used to render a modal; each ModalScreen has a specific config */
interface ModalConfig {
  /** Optional title; if this is not supplied then the title will be "Add a {federation type} Federation" */
  title?: string;

  /** Optional subtitle for the modal header */
  subtitle?: string;

  /** Content to render in the body of the modal */
  content: JSX.Element;

  /** (Optional) footer to render on the footer */
  footer?: JSX.Element;
}

/**
 * Returns a button to click to go to the next step in the add federation workflow.
 *
 * @param dispatch Redux dispatch function
 * @param nextScreen Next modal screen to show when button is clicked
 * @param busy Whether or not the button is busy
 * @param disabled (default: false) Whether not the button is disabled
 */
const getNextButton = (
  dispatch: Dispatch,
  nextScreen: AddFederationModalScreen,
  busy: boolean,
  disabled = false
) => (
  <Button
    className="next-done-btn"
    variant={VARIANTS.PRIMARY}
    onClick={() => dispatch(setModalScreen(nextScreen))}
    disabled={disabled || busy}
    busy={busy}
  >
    <div>
      {I18n.t('shared.components.stepper.next')}
      <SocrataIcon name={IconName.ArrowRight} />
    </div>
  </Button>
);

/** Modal config for AddFederationModalScreen.SELECT_FEDERATION_TYPE */
const getSelectFederationTypeModalConfig = (
  dispatch: Dispatch,
  type: Type,
  scope: Scope,
  busy: boolean
): ModalConfig => ({
  title: I18n.t('shared.federations.add_federation.federation_type.title'),
  subtitle: I18n.t('shared.federations.add_federation.federation_type.subtitle'),
  content: <FederationType />,
  footer: getNextButton(
    dispatch,

    // if the chosen type is catalog, we go to CNAME_SEARCH
    // otherwise, it's data and we want to display a warning with DATA_FEDERATION_INFORMATION
    type === Type.Catalog
      ? AddFederationModalScreen.CNAME_SEARCH
      : AddFederationModalScreen.DATA_FEDERATION_INFORMATION,
    busy
  )
});

/** Modal config for AddFederationModalScreen.DATA_FEDERATION_INFORMATION */
const getDataFederationInformationModalConfig = (
  dispatch: Dispatch,
  type: Type,
  scope: Scope,
  busy: boolean
): ModalConfig => ({
  content: <DataFederationInformation />,
  footer: getNextButton(dispatch, AddFederationModalScreen.CNAME_SEARCH, busy)
});

/** Modal config for AddFederationModalScreen.CNAME_SEARCH */
const getCnameSearchModalConfig = (
  dispatch: Dispatch,
  type: Type,
  scope: Scope,
  busy: boolean,
  targetDomain?: string
): ModalConfig => ({
  content: <CnameSearch />,
  footer: getNextButton(
    dispatch,
    AddFederationModalScreen.FEDERATION_OPTIONS,
    busy,
    targetDomain === undefined // button is disabled until we have a target domain
  )
});

/** Modal config for AddFederationModalScreen.FEDERATION_OPTIONS */
const getFederationOptionsModalConfig = (
  dispatch: Dispatch,
  type: Type,
  scope: Scope,
  busy: boolean
): ModalConfig => ({
  content: <FederationOptions />,

  // clicking this button will call the core API to initiate the federation
  footer: <SubmitFederationButton />
});

/**
 * This object contains a mapping of AddFederationModalScreen -> function to get config for that screen
 */
const modalConfigs: {
  [key in AddFederationModalScreen]: (
    dispatch: Dispatch,
    type: Type,
    scope: Scope,
    busy: boolean,
    targetDomain?: string
  ) => ModalConfig;
} = {
  [AddFederationModalScreen.CNAME_SEARCH]: getCnameSearchModalConfig,
  [AddFederationModalScreen.DATA_FEDERATION_INFORMATION]: getDataFederationInformationModalConfig,
  [AddFederationModalScreen.FEDERATION_OPTIONS]: getFederationOptionsModalConfig,
  [AddFederationModalScreen.SELECT_FEDERATION_TYPE]: getSelectFederationTypeModalConfig
};

interface AddFederationModalProps {
  /** Function to call to close the modal (if the user hits the cancel button) */
  onCloseModal: () => void;
}

/**
 * This modal is essentially a wizard to walk the user through setting up a new
 * federation. It lets them select the federation type, gives some warnings, validates CNAMEs, etc.
 */
const AddFederationModal: FunctionComponent<AddFederationModalProps> = ({ onCloseModal }) => {
  const dispatch = useDispatch();
  const modalScreen = useSelector(getCurrentModalScreen);
  const type = useSelector(getType);
  const scope = useSelector(getScope);
  const targetDomain = useSelector(getTargetDomainCname);
  const isBusy = useSelector(getIsBusy);

  const { title, subtitle, content, footer } = modalConfigs[modalScreen](
    dispatch,
    type,
    scope,
    isBusy,
    targetDomain
  );

  return (
    <Modal className="add-federation-modal" onDismiss={onCloseModal}>
      <ModalHeader
        className="add-federation-modal-header"
        onDismiss={onCloseModal}
        title={title || I18n.t(`shared.federations.add_federation.title.${scope}.${type}`)}
      >
        {subtitle}
      </ModalHeader>
      <ModalContent>{content}</ModalContent>
      <ModalFooter className="add-federation-modal-footer">
        {/* These buttons have to be in empty <div>s because they're wrapped in <span>s and thus can't be moved around properly */}
        <div>
          <Button onClick={onCloseModal} className="cancel-btn" variant={VARIANTS.DEFAULT}>
            {I18n.t('shared.components.stepper.cancel')}
          </Button>
        </div>
        <div>{footer}</div>
      </ModalFooter>
    </Modal>
  );
};

export default AddFederationModal;
