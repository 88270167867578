import React, { FunctionComponent } from 'react';
import { Type, Scope } from '@socrata/core-federations-api';

import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';
import { currentUserIsSuperAdmin } from 'common/current_user';

import { FederationAction } from '../../types';
import { ModalScreen } from './ActionCellModal';

export interface ActionCellActionProps {
  /**
   * The action to show for this cell.
   * This changes depending on a number of factors, such as the type of federation
   * and whether it has been accepted or not.
   * See the type definition for more details.
   */
  action: FederationAction;

  /** The type of federation that this row represents. */
  type: Type;

  /** The scope of federation this row represents */
  scope: Scope;

  /** Whether or not we're currently waiting for an async action to finish */
  busy: boolean;

  /** Function to change which screen is being shown by the ActionCellModal */
  setModalScreen: React.Dispatch<React.SetStateAction<ModalScreen>>;

  /** Function to call to delete the federation for this row */
  onDeleteFederation: () => Promise<void>;

  /** Function to call to accept the federation for this row */
  onAcceptFederation: () => Promise<void>;
}

/**
 * Renders the visible cell contents that invite the user to
 * accept, reject, terminate, or cancel a federation or request.
 *
 * Which specific UI is used depends on the `action` prop.
 */
const ActionCellActions: FunctionComponent<ActionCellActionProps> = ({
  action,
  type,
  scope,
  busy,
  setModalScreen,
  onDeleteFederation,
  onAcceptFederation
}) => {
  const onTerminateLinkClicked = () => {
    if (type === Type.Data) {
      if (currentUserIsSuperAdmin()) {
        // Currently, only superadmins (i.e. employees) are allowed
        // to terminate internal-to-public (a.k.a. data) federations
        //
        // This is mainly because doing so can end up breaking a _lot_ of assets,
        // so we don't want to allow customers to do it whenever they want.
        setModalScreen(ModalScreen.CONFIRM_TERMINATE_DATA_FEDERATION);
      } else {
        // Show a nice message about how they should contact support :)
        setModalScreen(ModalScreen.CANNOT_TERMINATE_DATA_FEDERATION);
      }
    } else {
      // Confirmation modal to confirm deleting the catalog federation
      setModalScreen(ModalScreen.CONFIRM_TERMINATE_CATALOG_FEDERATION);
    }
  };

  const onCancelRequestLinkClicked = () => {
    setModalScreen(ModalScreen.CONFIRM_CANCEL_REQUEST);
  };

  switch (action) {
    // Terminate an existing, approved federation
    case FederationAction.TERMINATE_FEDERATION:
      return (
        <Button
          variant={VARIANTS.LINK}
          className="terminate-link"
          onClick={onTerminateLinkClicked}
          disabled={busy}
        >
          {I18n.t('shared.federations.actions.terminate')}
        </Button>
      );

    // Cancel a pending outgoing federation
    case FederationAction.CANCEL_REQUEST:
      return (
        <Button variant={VARIANTS.LINK} onClick={onCancelRequestLinkClicked} disabled={busy}>
          {I18n.t('shared.federations.actions.cancel_request')}
        </Button>
      );

    // Approve or reject a pending incoming federation
    case FederationAction.APPROVE_OR_REJECT_REQUEST:
      return (
        <div>
          <Button
            variant={VARIANTS.LINK}
            className="federation-accept-button"
            onClick={onAcceptFederation}
            disabled={busy}
          >
            {I18n.t('shared.federations.actions.accept')}
          </Button>
          <Button
            variant={VARIANTS.LINK}
            className="federation-reject-button"
            onClick={onDeleteFederation}
            disabled={busy}
          >
            {I18n.t('shared.federations.actions.reject')}
          </Button>
        </div>
      );
  }
};

export default ActionCellActions;
