import React, { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-query';

import { Federation, ErrorResponse } from '@socrata/core-federations-api';

import FederationsApi, { parseError, coreErrorCodeToTranslationString } from 'common/core/federations';
import Spinner from 'common/components/Spinner';
import Alert, { AlertType } from 'common/components/Alert';

import { searchFederations } from '../helpers';
import { TableTab } from '../types';
import FederationsTable from './FederationsTable';
import FederationsSearchBar from './FederationsSearchBar';
import FederationsHeader from './FederationsHeader';

const FederationsManager: FunctionComponent = () => {
  const [query, setQuery] = useState<string | undefined>('');
  const [activeTab, setActiveTab] = useState(TableTab.ALL);

  const {
    data: federationsResponse,
    isLoading,
    isError,
    error
  } = useQuery<Federation[], ErrorResponse>('federations-list', async () => {
    try {
      return await FederationsApi.getFederationsForCurrentDomain();
    } catch (apiError) {
      console.error(apiError);
      throw await parseError(apiError);
    }
  });

  // we filter out federations without a type and without a target domain
  // (note from the author: I'm not sure why we do this and if there are ever federations that come back like this?)
  const filtered =
    federationsResponse?.filter((federation) => federation.targetDomainCName && federation.type) || [];
  const federations = searchFederations(filtered, query, activeTab);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError && error) {
    return <Alert type={AlertType.Error}>{coreErrorCodeToTranslationString(error.code)}</Alert>;
  }

  return (
    <div>
      <FederationsHeader
        activeTab={activeTab}
        federations={federations}
        onTabChanged={(tab: TableTab) => setActiveTab(tab)}
      />
      <FederationsSearchBar onSearch={(newQuery?: string) => setQuery(newQuery)} federations={federations} />
      <FederationsTable federations={federations} />
    </div>
  );
};

export default FederationsManager;
