import { AddFederationState } from './reducer';

export const getCurrentModalScreen = (state: AddFederationState) => state.modalScreen;
export const getType = (state: AddFederationState) => state.type;
export const getScope = (state: AddFederationState) => state.scope;
export const getSearchBoost = (state: AddFederationState) => state.searchBoost;
export const getTargetDomainCname = (state: AddFederationState) => state.validatedCname;
export const getInititateFederationError = (state: AddFederationState) => state.initiateFederationError;
export const getIsBusy = (state: AddFederationState) => state.busy;
export const getAudienceLevel = (state: AddFederationState) => state.audienceLevel;
