import React, { FunctionComponent } from 'react';
import I18n from 'common/i18n';

const scope = 'shared.federations.add_federation.data_federation_information';

/**
 * This component shows a nice big warning to users about some of the
 * current caveats of setting up a data federation (i.e. that some assets won't federate)
 */
const DataFederationInformation: FunctionComponent = () => (
  <div className="data-federation-information">
    <h6>{I18n.t('title', { scope })}</h6>
    <ul dangerouslySetInnerHTML={{ __html: I18n.t('notes', { scope }) }} />
  </div>
);

export default DataFederationInformation;
