import { createStore, Store, compose } from 'redux';

import { Type, Scope } from '@socrata/core-federations-api';

import { DefaultSearchBoost } from '../../../helpers';
import reducer, { AddFederationState, Actions } from './reducer';
import { AddFederationModalScreen } from '../AddFederationModal';

export type StoreType = Store<AddFederationState, Actions>;

export default (
  startingModalScreen: AddFederationModalScreen,
  initialState?: Partial<AddFederationState>
): StoreType => {
  const state: AddFederationState = {
    busy: false,

    // we start this out as "catalog"... this makes it so that if the domain is _not_ set up for outgoing
    // data federation, we default to catalog federation. If the domain _is_ set up for outgoing data federation,
    // then the user is presented with a screen where they can choose the federation type they want to set up
    type: Type.Catalog,
    scope: Scope.Audience,
    searchBoost: DefaultSearchBoost,
    modalScreen: startingModalScreen,

    // passed in state can override defaults
    ...initialState
  };

  // use the redux devtool's composeEnhancers to keep them around
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'Add Federation Modal' })) ||
    compose;

  return createStore<AddFederationState, Actions, null, any>(reducer, state, composeEnhancers());
};
