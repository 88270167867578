import { action } from 'typesafe-actions';

import { Type, Scope, AudienceLevel } from '@socrata/core-federations-api';

import { AddFederationModalScreen } from '../AddFederationModal';

export enum ActionTypes {
  SET_FEDERATION_TYPE = 'SET_FEDERATION_TYPE',
  SET_TARGET_CNAME = 'SET_TARGET_CNAME',
  SET_SEARCH_BOOST = 'SET_SEARCH_BOOST',
  SET_INITIATE_FEDERATION_ERROR = 'SET_INITIATE_FEDERATION_ERROR',
  SET_MODAL_SCREEN = 'SET_MODAL_SCREEN',
  SET_BUSY = 'SET_BUSY',
  SET_AUDIENCE_LEVEL = 'SET_AUDIENCE_LEVEL'
}

export const setFederationType = (type: Type, scope: Scope) =>
  action(ActionTypes.SET_FEDERATION_TYPE, { type, scope });

export const setTargetCname = (targetCname?: string) => action(ActionTypes.SET_TARGET_CNAME, { targetCname });

export const setSearchBoost = (searchBoost: string) => action(ActionTypes.SET_SEARCH_BOOST, { searchBoost });

export const setInitiateFederationError = (initiateFederationError: string) =>
  action(ActionTypes.SET_INITIATE_FEDERATION_ERROR, { initiateFederationError });

export const setModalScreen = (modalScreen: AddFederationModalScreen) =>
  action(ActionTypes.SET_MODAL_SCREEN, { modalScreen });

export const setBusy = (busy: boolean) => action(ActionTypes.SET_BUSY, { busy });

export const setAudienceLevel = (audienceLevel?: AudienceLevel) =>
  action(ActionTypes.SET_AUDIENCE_LEVEL, { audienceLevel });
