import React, { FunctionComponent } from 'react';

import { Federation, FederationFlags, Type } from '@socrata/core-federations-api';

import I18n from 'common/i18n';
import ResultsTable from 'common/components/ResultsTable';
import {
  FederationTypeColumn,
  SourceDomainColumn,
  TargetDomainColumn,
  AudienceColumn,
  SearchBoostColumn,
  ContactColumn,
  StatusColumn
} from 'common/components/Federation/FederationTableColumns';

import ActionCell from './ActionCell';

interface FederationTableProps {
  federations: Federation[];
}

const FederationTable: FunctionComponent<FederationTableProps> = ({ federations }) => {
  return (
    <div>
      <ResultsTable
        data={federations}
        rowKey="id"
        noResultsMessage={I18n.t('shared.federations.no_results')}
        id="federations-table"
      >
        {SourceDomainColumn}
        {TargetDomainColumn}
        {FederationTypeColumn}
        {AudienceColumn}
        {SearchBoostColumn}
        {ContactColumn}
        {StatusColumn}
        <ResultsTable.Column
          header={I18n.t('shared.federations.headers.actions')}
          headerClassName="actions"
          dataClassName="actions"
          dataIndex="id"
          cellComponent={ActionCell}
        />
      </ResultsTable>
    </div>
  );
};

export default FederationTable;
