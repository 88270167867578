import React, { FunctionComponent, useState } from 'react';
import { Provider } from 'react-redux';

import FeatureFlags from 'common/feature_flags';
import I18n from 'common/i18n';
import Button, { VARIANTS, SIZES } from 'common/components/Button';

import createStore, { StoreType } from './redux/store';
import AddFederationModal, { AddFederationModalScreen } from './AddFederationModal';

// used for translation strings
const scope = 'shared.federations.add_federation';

/**
 * @returns The modal screen that should be shown initially
 *  This depends on the feature flags on the domain.
 */
export const getStartingModalScreen = (): AddFederationModalScreen => {
  // Is this an eligible data federation source – i.e. is it an internal site?
  const shouldShowFederationTypeSelectScreen = FeatureFlags.value('strict_permissions');

  return shouldShowFederationTypeSelectScreen
    ? AddFederationModalScreen.SELECT_FEDERATION_TYPE
    : AddFederationModalScreen.CNAME_SEARCH;
};

const renderModal = (store: StoreType, closeModal: () => void) => (
  <Provider store={store}>
    <AddFederationModal onCloseModal={closeModal} />
  </Provider>
);

const AddFederation: FunctionComponent = () => {
  const [showingModal, setShowingModal] = useState(false);

  // we actually keep the store in the state of this component here because
  // it has to have different defaults depending on site config...
  // also, we re-create the state and store when the modal is closed/re-opened
  // so that it gets reset
  const [store, setStore] = useState<StoreType>();

  const onShowModal = () => {
    // create a new store with a different default starting screen depending on site config
    setStore(createStore(getStartingModalScreen()));

    setShowingModal(true);
  };

  return (
    <div className="add-federation dash-group">
      <Button onClick={onShowModal} variant={VARIANTS.PRIMARY} inverse={true} size={SIZES.SMALL}>
        {I18n.t('open_modal_button', { scope })}
      </Button>
      {showingModal && store && renderModal(store, () => setShowingModal(false))}
    </div>
  );
};

export default AddFederation;
