import React, { FunctionComponent, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import type { Type, Scope, AudienceLevel } from '@socrata/core-federations-api';

import FederationsApi, { parseError, coreErrorCodeToTranslationString } from 'common/core/federations';
import I18n from 'common/i18n';
import { Form, Input } from 'common/components/Forms';
import Button, { VARIANTS } from 'common/components/Button';
import SocrataIcon, { IconName } from 'common/components/SocrataIcon';

import { getType, getScope, getIsBusy } from './redux/selectors';
import { setTargetCname, setBusy, setAudienceLevel } from './redux/actions';

/**
 * Validate an outgoing federation based on the type and given CNAME.
 * This hits core to determine if the relationship is valid.
 *
 * @param setCnameValid Function to set whether or not the cname is valid
 * @param setCnameDirty Function to set whether the cname is "dirty" (has been changed since the last validation)
 * @param setTargetDomainCName  Function to call to update this component's state tracking the input value
 * @param setErrorTranslationKey Function to call to set the error message to show
 * @param setBusy Function to set the overall modal to be busy or not
 * @param setCnameInState Function to set the target domain CNAME in the redux store.
 *                        This being present means that the CNAME has been validated and the user can move on to the next step.
 * @param targetDomainCName Current target domain cname in this component's state
 * @param type The selected type of federation
 * @param scope The selected scope of federation
 */
export const validateFederation = async (
  updateProposedFederationStatusInState: (
    validatedCname: string,
    cnameValid: boolean,
    errorTranslationKey?: string,
    audienceLevel?: AudienceLevel
  ) => void,
  onSetBusy: (busy: boolean) => void,
  targetDomainCName: string,
  type: Type,
  scope: Scope
): Promise<void> => {
  // if the user supplied a full URL like https://my-city.opendata.gov/browse
  // then grab the hostname, otherwise just validate the user's raw input
  const validatedCname =
    targetDomainCName.indexOf('http') < 0 ? targetDomainCName : new URL(targetDomainCName).hostname;

  // the cname must be nonempty and it must not be the same as the current site
  if (!validatedCname || validatedCname === document.domain) {
    return updateProposedFederationStatusInState(
      validatedCname,
      false,
      'shared.federations.add_federation.errors.cname_invalid'
    );
  }

  try {
    onSetBusy(true);

    // this will return a 200 for a valid federation, or throw if it's not
    const [nothing, audienceLevel] = await Promise.all([
      FederationsApi.isValidFederation({ targetDomainCName: validatedCname, type, scope }),
      FederationsApi.getAudienceLevelForProposedTarget({ targetDomainCName: validatedCname })
    ]);
    updateProposedFederationStatusInState(validatedCname, true, undefined, audienceLevel);
  } catch (error) {
    const errorJson = await parseError(error);
    updateProposedFederationStatusInState(
      validatedCname,
      false,
      coreErrorCodeToTranslationString(errorJson.code)
    );
  } finally {
    onSetBusy(false);
  }
};

/**
 * Render a button that, when clicked, will validate the entered CNAME.
 * If the CNAME has already been validated, then this will show a nice checkmark.
 *
 * @param cnameValid Whether or not the CNAME has been validated
 * @param validationDisabled Whether or not this button should be disabled.
 */
export const renderValidationButton = (
  onClick: () => void,
  cnameValid: boolean,
  validationDisabled: boolean,
  busy: boolean
) => {
  const buttonProps = {
    variant: VARIANTS.PRIMARY,
    inverse: false,
    disabled: validationDisabled,
    busy,
    onClick
  };

  if (cnameValid) {
    // if the cname is valid, we show a nice check mark
    buttonProps.inverse = true;
    return (
      <Button className="validate-button federation-validated-button" type="submit" {...buttonProps}>
        <SocrataIcon name={IconName.Check2} />
        {I18n.t('shared.federations.add_federation.validated')}
      </Button>
    );
  } else {
    // clicking this button will trigger validation to happen
    return (
      <Button className="validate-button validate-federation-button" type="submit" {...buttonProps}>
        {I18n.t('shared.federations.add_federation.validate')}
      </Button>
    );
  }
};

/**
 * Render a validation error, if there is one.
 *
 * @param targetDomainCName CNAME that has been entered into the text box
 * @param cnameDirty
 * @param errorTranslationKey Translation key for error. If this is not present, then there is no error currently.
 */
const renderValidationError = (targetDomainCName: string, errorTranslationKey?: string) => {
  if (isEmpty(errorTranslationKey)) {
    return null;
  }

  return (
    <div
      className="cname-invalid-error small"
      dangerouslySetInnerHTML={{
        __html: I18n.t(errorTranslationKey, { domain: targetDomainCName })
      }}
    />
  );
};

/**
 * This component renders a text input with a "Validate" button that is used
 * to determine, given a domain CNAME, if it is possible to set up a federation relationship
 * from the current (source) domain out to the target domain.
 */
const CnameSearch: FunctionComponent = () => {
  const dispatch = useDispatch();

  // we do slightly different validations based on the type of federation
  const type = useSelector(getType);
  const scope = useSelector(getScope);
  const isBusy = useSelector(getIsBusy);

  // this tracks whether or not we've validated the entered cname or not
  // if this if false, then the validate button is enabled
  const [cnameValid, setCnameValid] = useState(false);

  // tracks what's been entered into the input box
  const [targetDomainCName, setTargetDomainCName] = useState('');

  // if this is present, it will be the translation key to use to display an error
  // that happened during validations
  const [errorTranslationKey, setErrorTranslationKey] = useState<string | undefined>();

  // function to update the state when the user is typing into the input
  const onCnameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCname = e.target.value.trim();
    if (enteredCname != targetDomainCName) {
      dispatch(setTargetCname(undefined));
      setCnameValid(false);
      setErrorTranslationKey(undefined);
      setTargetDomainCName(enteredCname);
    }
  };

  // this function is called once the async call to check the cname's validity is completed
  const updateProposedFederationStatusInState = (
    validatedCname: string,
    isCnameValid: boolean,
    maybeErrorTranslationKey?: string,
    audienceLevel?: AudienceLevel
  ) => {
    setCnameValid(isCnameValid);
    setErrorTranslationKey(maybeErrorTranslationKey);
    setTargetDomainCName(validatedCname);

    // if the cname if valid, we actually update it in the redux state
    // this triggers all sorts of things, like un-disabling the next button in the AddFederationModal
    if (isCnameValid) {
      dispatch(setTargetCname(validatedCname));
      dispatch(setAudienceLevel(audienceLevel));
    }
  };

  // triggers an async call to validate the entered cname, the result of which is passed to updateProposedFederationStatusInState
  const onValidateButtonClicked = () =>
    validateFederation(
      updateProposedFederationStatusInState,
      (busy: boolean) => dispatch(setBusy(busy)),
      targetDomainCName,
      type,
      scope
    );

  const validationDisabled =
    // It's already been validated
    cnameValid ||
    // There's nothing at all to validate
    isEmpty(targetDomainCName);

  return (
    <Form
      onSubmit={(e: React.FormEvent<HTMLFormElement>) => e.preventDefault()}
      className="federation-cname-search"
    >
      <div className="cname-input-group">
        <Input
          containerClassName="cname-container"
          className="cname-input"
          autoFocus
          label={I18n.t('shared.federations.add_federation.cname_search')}
          value={targetDomainCName || ''}
          onChange={onCnameInputChange}
        />
        {renderValidationButton(onValidateButtonClicked, cnameValid, validationDisabled, isBusy)}
      </div>
      {renderValidationError(targetDomainCName, errorTranslationKey)}
    </Form>
  );
};

export default CnameSearch;
