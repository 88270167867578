import React, { FunctionComponent } from 'react';

import { Federation, Type } from '@socrata/core-federations-api';

import I18n from 'common/i18n';

/**
 * Given a list of federations and a type and incoming/outgoing, will return the number of federations that match.
 *
 * @param federations List of federations
 * @param type Type to count
 * @param isIncomingFederation Whether to count incoming/outgoing federations
 * @returns Number of federations that match
 */
const getFederationsOfTypeCount = (federations: Federation[], type: Type, isIncomingFederation: boolean) =>
  federations.filter(
    (federation) => federation.type === type && federation.isIncomingFederation === isIncomingFederation
  ).length;

interface FederationsCountProps {
  federations: Federation[];
}

/**
 * Lists all of the incoming/outgoing federations for the current domain
 */
const FederationsCount: FunctionComponent<FederationsCountProps> = ({ federations }) => (
  <div className="dash-group">
    <div className="dash-statistic">
      <p className="dash-statistic-count data-in" data-testid="federation-count-data-in">
        {getFederationsOfTypeCount(federations, Type.Data, true)}
      </p>
      <p className="dash-statistic-label data-in">{I18n.t('shared.federations.type.data_in')}</p>
    </div>
    <div className="dash-statistic">
      <p className="dash-statistic-count data-out" data-testid="federation-count-data-out">
        {getFederationsOfTypeCount(federations, Type.Data, false)}
      </p>
      <p className="dash-statistic-label data-out">{I18n.t('shared.federations.type.data_out')}</p>
    </div>
    <div className="dash-statistic">
      <p className="dash-statistic-count catalog-in" data-testid="federation-count-catalog-in">
        {getFederationsOfTypeCount(federations, Type.Catalog, true)}
      </p>
      <p className="dash-statistic-label catalog-in">{I18n.t('shared.federations.type.catalog_in')}</p>
    </div>
    <div className="dash-statistic">
      <p className="dash-statistic-count catalog-out" data-testid="federation-count-catalog-out">
        {getFederationsOfTypeCount(federations, Type.Catalog, false)}
      </p>
      <p className="dash-statistic-label catalog-out">{I18n.t('shared.federations.type.catalog_out')}</p>
    </div>
  </div>
);

export default FederationsCount;
