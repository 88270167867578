import React, { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import times from 'lodash/times';

import { Type } from '@socrata/core-federations-api';

import I18n from 'common/i18n';
import { getCurrentDomain } from 'common/currentDomain';
import BlockLabel from 'common/components/BlockLabel';
import Dropdown from 'common/components/Dropdown';
import { Form } from 'common/components/Forms';
import 'common/types/windowSocrata';

import {
  getType,
  getTargetDomainCname,
  getInititateFederationError,
  getSearchBoost,
  getAudienceLevel
} from './redux/selectors';
import { setSearchBoost } from './redux/actions';

/**
 * If the federation type is catalog, then there are a few extra options that users can select
 * @param handleWorkflowStateChange Function to call to set overall workflow state
 * @param searchBoost Currently selected search boost value
 * @param busy Whether or not the form is busy (submitted and waiting on a response)
 */
const renderCatalogFederationOptions = (
  onSearchBoostChange: (searchBoost: string) => void,
  searchBoost?: string,
  busy?: boolean
) => {
  const searchBoostOptions = times(11, (n) => {
    const boost = (1 - n * 0.1).toFixed(1);
    return { title: boost, value: boost };
  });

  return (
    <div className="option">
      <BlockLabel
        htmlFor="search-boost"
        title={I18n.t('shared.federations.add_federation.search_boost.title')}
        description={I18n.t('shared.federations.add_federation.search_boost.description')}
        iconAlignment="right"
      />
      <Dropdown
        id="search-boost"
        className="option-value"
        disabled={busy}
        autoFocus
        options={searchBoostOptions}
        value={searchBoost}
        onSelection={({ value }: { value: string }) => onSearchBoostChange(value)}
      />
    </div>
  );
};

/**
 * Render an error message
 * @param initiateFederationError Error message translation key
 * @param targetDomainCName Passed to the translation in case it needs it
 */
const renderErrorMessage = (initiateFederationError: string, targetDomainCName?: string) => (
  <div
    className="cname-invalid-error small"
    dangerouslySetInnerHTML={{
      __html: I18n.t(initiateFederationError, { domain: targetDomainCName })
    }}
  />
);

/**
 * This is actually the "final" step in the stepper.
 *
 * It shows the selected federation type, cname, etc. and
 * allows the user to select some other options (i.e. search boost for catalog federations)
 */
const FederationOptions: FunctionComponent = () => {
  const dispatch = useDispatch();

  const type = useSelector(getType);
  const targetCname = useSelector(getTargetDomainCname);
  const searchBoost = useSelector(getSearchBoost);
  const initiateFederationError = useSelector(getInititateFederationError);
  const audienceLevel = useSelector(getAudienceLevel);

  const isCatalogFederation = type === Type.Catalog;

  const onSearchBoostChange = (newSearchBoost: string) => dispatch(setSearchBoost(newSearchBoost));
  const assetsIncluded = I18n.t(`shared.federations.add_federation.assets.options.${audienceLevel}`);

  // this form is never actually submitted
  return (
    <Form id="create-federation-form" className="federation-options">
      <div>{I18n.t('shared.federations.add_federation.request_explanation')}</div>
      <section className="summary">
        <div className="option">
          <BlockLabel
            htmlFor="source-domain"
            title={I18n.t('shared.federations.add_federation.source_domain')}
          />
          <div id="source-domain" className="option-value">
            {I18n.t('shared.federations.add_federation.this_site', { domain: getCurrentDomain() })}
          </div>
        </div>
        <div className="option">
          <BlockLabel
            htmlFor="target-domain"
            title={I18n.t('shared.federations.add_federation.target_domain')}
          />
          <div id="target-domain" className="option-value">
            {targetCname}
          </div>
        </div>
        <div className="option">
          <BlockLabel
            htmlFor="assets-included"
            title={I18n.t('shared.federations.add_federation.assets.title')}
            description={I18n.t('shared.federations.add_federation.assets.description')}
            iconAlignment="right"
          />
          <div id="assets-included" className="option-value">
            {assetsIncluded}
          </div>
        </div>

        {isCatalogFederation && renderCatalogFederationOptions(onSearchBoostChange, searchBoost)}
      </section>

      {initiateFederationError && renderErrorMessage(initiateFederationError, targetCname)}
    </Form>
  );
};

export default FederationOptions;
