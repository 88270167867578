/**
 * The adventures of Action Cell. Accept federations, reject federations!
 */
import React, { FunctionComponent, useState } from 'react';

import { Federation, AcceptFederationRequest, DeleteFederationRequest } from '@socrata/core-federations-api';

import I18n from 'common/i18n';
import { reload as windowLocationReload } from 'common/window_location';
import FederationsApi, { parseError, coreErrorCodeToTranslationString } from 'common/core/federations';
import Modal, { ModalContent, ModalFooter } from 'common/components/Modal';
import Button, { VARIANTS } from 'common/components/Button';
import { showToastOnPageReload, ToastType } from 'common/components/ToastNotification/Toastmaster';

import { FederationAction } from '../../types';
import ActionCellActions from './ActionCellActions';
import ActionCellModal, { ModalScreen } from './ActionCellModal';

/**
 * Given a federation, returns what action to show in the action cell
 * @param federation Federation to get action for
 */
export const getAction = (federation: Federation): FederationAction => {
  if (federation.acceptedUserId) {
    return FederationAction.TERMINATE_FEDERATION;
  }

  if (federation.isIncomingFederation) {
    return FederationAction.APPROVE_OR_REJECT_REQUEST;
  }

  return FederationAction.CANCEL_REQUEST;
};

/**
 * Makes a call to core's federations API and handles updating UI state etc.
 *
 * @param action Federation API action to call
 * @param federationId Federation ID to make call for
 * @param setBusy Function to call to set the UI as busy
 * @param setErrorTranslationKey Used to set an error translation key if an error occurs
 */
const doFederationApiCall = async (
  action: (request: AcceptFederationRequest | DeleteFederationRequest) => Promise<any>,
  federationId: number,
  setBusy: React.Dispatch<React.SetStateAction<boolean>>,
  setErrorTranslationKey: React.Dispatch<React.SetStateAction<string | undefined>>,
  actionKey: FederationAction,
  isAccept: boolean
) => {
  setBusy(true);

  const successTranslationKey = (federationAction: FederationAction, isAcceptAction: boolean) => {
    switch (federationAction) {
      // Terminate an existing, approved federation
      case FederationAction.TERMINATE_FEDERATION:
        return 'deleted';
      // Cancel a pending outgoing federation
      case FederationAction.CANCEL_REQUEST:
        return 'cancelled';
      // Approve or reject a pending incoming federation
      case FederationAction.APPROVE_OR_REJECT_REQUEST:
        return isAcceptAction ? 'accepted' : 'rejected';
    }
  };

  try {
    await action({ federationId });
    const scope = 'screens.admin.federation.flashes';
    showToastOnPageReload({
      type: ToastType.SUCCESS,
      content: I18n.t(successTranslationKey(actionKey, isAccept), { scope })
    });
    windowLocationReload();
  } catch (error) {
    const parsed = await parseError(error);
    console.error(`Error changing federation ${federationId}`, parsed);
    setErrorTranslationKey(coreErrorCodeToTranslationString(parsed.code));
    setBusy(false);
  }
};

/**
 * Renders a modal with a translated error in it, if there is an error to show.
 *
 * @param errorTranslationKey Translation key of error to show
 * @param setErrorTranslationKey Function to call to set error (used to blank it out and close the modal)
 */
const renderErrorModal = (
  errorTranslationKey: string | undefined,
  setErrorTranslationKey: React.Dispatch<React.SetStateAction<string | undefined>>
) => {
  if (!errorTranslationKey) {
    return null;
  }

  const onDismiss = () => setErrorTranslationKey(undefined);

  <Modal onDismiss={onDismiss}>
    <ModalContent>
      <div className="alert error">{I18n.t(errorTranslationKey)}</div>
    </ModalContent>
    <ModalFooter>
      <Button variant={VARIANTS.DEFAULT} onClick={onDismiss}>
        {I18n.t('shared.components.confirmation.agree')}
      </Button>
    </ModalFooter>
  </Modal>;
};

/**
 * The ActionCell will show different actions that can be taken on a given federation.
 *
 * The ActionCellActions component lists the potential actions
 * The ActionCellModal will pop up in some cases, i.e. to confirm deleting a federation
 */
const ActionCell: FunctionComponent<Federation> = (federation) => {
  const { type, scope, id: federationId } = federation;

  // controls which modal "screen" is being shown
  const [modalScreen, setModalScreen] = useState(ModalScreen.NONE);

  // controls whether this whole UI is considered busy or not
  const [busy, setBusy] = useState(false);

  // used to show/clear an error message
  const [errorTranslationKey, setErrorTranslationKey] = useState<string | undefined>(undefined);

  const action = getAction(federation);

  const onDeleteFederation = () =>
    doFederationApiCall(
      FederationsApi.deleteFederation.bind(FederationsApi),
      federationId,
      setBusy,
      setErrorTranslationKey,
      action,
      false
    );

  const onAcceptFederation = () =>
    doFederationApiCall(
      FederationsApi.acceptFederation.bind(FederationsApi),
      federationId,
      setBusy,
      setErrorTranslationKey,
      action,
      true
    );

  return (
    <div>
      <ActionCellActions
        onDeleteFederation={onDeleteFederation}
        onAcceptFederation={onAcceptFederation}
        action={action}
        type={type!}
        scope={scope!}
        busy={busy}
        setModalScreen={setModalScreen}
      />
      <ActionCellModal
        onDeleteFederation={onDeleteFederation}
        busy={busy}
        modalScreen={modalScreen}
        setModalScreen={setModalScreen}
      />
      {renderErrorModal(errorTranslationKey, setErrorTranslationKey)}
    </div>
  );
};

export default ActionCell;
