import React, { FunctionComponent } from 'react';
import { Dispatch } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import { Type, Scope } from '@socrata/core-federations-api';

import I18n from 'common/i18n';
import Button, { VARIANTS } from 'common/components/Button';
import FederationsApi, { parseError, coreErrorCodeToTranslationString } from 'common/core/federations';
import { reload as windowLocationReload } from 'common/window_location';

import { DefaultSearchBoost } from '../../helpers';
import { getType, getScope, getTargetDomainCname, getIsBusy, getSearchBoost } from './redux/selectors';
import { setBusy, setInitiateFederationError } from './redux/actions';

/**
 * Submits a federation using the given config
 *
 * @param dispatch Redux dispatch
 * @param type Type of federation to initiate
 * @param scope Scope of federation to initiate
 * @param targetDomainCName Target domain CNAME for federation
 * @param searchBoost Search boost (only valid for catalog federation)
 */
export const onSubmitFederation = async (
  dispatch: Dispatch,
  type: Type,
  scope: Scope,
  targetDomainCName: string,
  searchBoost?: string
) => {
  try {
    dispatch(setBusy(true));

    await FederationsApi.initiateFederation({
      targetDomainCName,
      type,
      scope,

      // no searchBoost for data federation
      searchBoost: type === Type.Catalog ? Number.parseFloat(searchBoost || DefaultSearchBoost) : undefined
    });

    // we do the lazy thing here and just reload the page to show the new federation
    windowLocationReload();

    // note that we don't set busy to false here since it reloads the page
  } catch (error) {
    const parsed = await parseError(error);
    dispatch(setInitiateFederationError(coreErrorCodeToTranslationString(parsed.code)));
    dispatch(setBusy(false));
  }
};

/**
 * Renders a button that, when clicked, submits the federation to core.
 * This is the "final" step in the add federation workflow.
 */
const SubmitFederationButton: FunctionComponent = () => {
  const dispatch = useDispatch();

  const targetDomainCName = useSelector(getTargetDomainCname);
  const type = useSelector(getType);
  const scope = useSelector(getScope);
  const searchBoost = useSelector(getSearchBoost);
  const isBusy = useSelector(getIsBusy);

  return (
    <Button
      variant={VARIANTS.PRIMARY}
      onClick={() => onSubmitFederation(dispatch, type, scope, targetDomainCName!, searchBoost)}
      busy={isBusy}
    >
      {I18n.t('shared.federations.add_federation.request_federation')}
    </Button>
  );
};

export default SubmitFederationButton;
