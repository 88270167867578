/**
 * The set of actions we show the user.
 * This is mainly relevant in the "ActionCellActions" component.
 * @see ActionCellActions
 */
export enum FederationAction {
  /** Shown when a federation has been accepted and can be terminated */
  TERMINATE_FEDERATION = 'terminate_federation',

  /**
   * Shown only on the target domain when an outgoing federation has been requested
   * but has not yet been accepted. Clicking the button will cancel the outgoing
   * federation request.
   */
  CANCEL_REQUEST = 'cancel_request',

  /**
   * Shown only on the source domain when an incoming federation has been requested
   * but has not yet been accepted.
   *
   * This shows two buttons; one to accept it and one to reject it.
   */
  APPROVE_OR_REJECT_REQUEST = 'approve_or_reject_request'
}

/**
 * A filter tab at the top of the table.
 */
export enum TableTab {
  /**
   * All federations.
   */
  ALL,
  /**
   * Outgoing federations only.
   */
  OUT,
  /**
   * Incoming federations only.
   */
  IN
}
