import React, { Component } from 'react';
import classNames from 'classnames';

import I18n from 'common/i18n';

import { TableTab } from '../types';

interface Props {
  activeTab: TableTab;
  onTabChanged: (filter: TableTab) => void;
}

const scope = 'shared.federations.tabs';

const FederationTableTabs = ({ activeTab, onTabChanged }: Props) => {
  const renderTab = (tab: TableTab, textKey: string) => {
    const tabClass = classNames('tab-link', { current: tab === activeTab });

    return (
      <li className={tabClass}>
        <button data-testid={`federation-table-tab-${tab}`} onClick={() => onTabChanged(tab)}>
          {I18n.t(textKey, { scope })}
        </button>
      </li>
    );
  };

  return (
    <ul className="nav-tabs">
      {renderTab(TableTab.ALL, 'all_federations')}
      {renderTab(TableTab.OUT, 'outgoing_federations')}
      {renderTab(TableTab.IN, 'incoming_federations')}
    </ul>
  );
};

export default FederationTableTabs;
