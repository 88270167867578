import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import FederationsManager from './components/FederationsManager';

const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <FederationsManager />
  </QueryClientProvider>,
  document.querySelector('#federations-manager')
);
